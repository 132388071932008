<div *ngIf="partDetail" class="part-content">
    <button
        *ngIf="part.sizeX > 2"
        [id]="'id-' + part.id"
        mat-fab
        (click)="onClick()"
        color="primary"
        [matTooltip]="partDetail.processedTooltip"
        [disabled]="!partDetail.enabled">
        <mat-icon>{{ partDetail.processedIcon }}</mat-icon>
    </button>
    <button
        *ngIf="part.sizeX <= 2"
        [id]="'id-' + part.id"
        mat-mini-fab
        (click)="onClick()"
        color="primary"
        [matTooltip]="partDetail.processedTooltip"
        [disabled]="!partDetail.enabled">
        <mat-icon>{{ partDetail.processedIcon }}</mat-icon>
    </button>
</div>
